@import '../../styles/animations';
@import '../../styles/colors';
@import '../../styles/fonts';

.loader {
    animation-name: fade-in;
    animation-duration: 500ms;
    animation-delay: 0ms;
    animation-fill-mode: both; // style for the target element when the animation is not playing
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); // animation speed curve
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__text {
        font-family: $font-family-bold;

        &--detail {
            color: $zambezi;
            font-size: 14px;
        }
    }
}