@import '../../../styles/variables';

.hnz-cart-dialog {
	.MuiPaper-rounded {
		border-radius: 9px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-content: center;
		padding-bottom: 20px !important;

		&>*:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	&__heading {
		font-size: 16px;
		line-height: 19px;
		padding: 10px;
		text-align: center;
		font-family: $font-family-semi-bold;
	}

	&__body {
		padding: 10px;
		text-align: center;
		font-family: $font-family;
		font-size: 12px;
		line-height: 15px;
		color: $star-dust;
	}
}