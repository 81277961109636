@import '../../../styles/variables';

/* HNZ PAGES */

.page {
    position: relative;
    width: 100%;
    height: 540px;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    padding: 15px;
    box-sizing: border-box;
    overflow: hidden;

    &__heading {
        display: flex;
        justify-content: space-between;
    }

    &__heading--end {
        justify-content: flex-end;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        max-height: 20%;
        padding-top: 10px;
    }

    &__heading>*,
    &__footer>* {
        z-index: 1;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__boxed-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        padding: 10px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        background-color: $white;
        z-index: 1;
    }

    &__text-container {
        display: flex;
        justify-content: center;
    }

    /* DIVIDERS */

    &__divider.MuiDivider-root {
        width: 30%;
        background-color: $dimGrey;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    &__boxed-content .page__divider.MuiDivider-root {
        width: 80%;
        margin-bottom: 20px;
    }

    /* IMAGES */

    img {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        pointer-events: none
    }

    img:not(.page__background) {
        z-index: 1;
    }

    &__logo--small {
        width: 20px;
        height: 20px;
        object-fit: contain;
        font-family: 'object-fit: contain';
        margin-left: 10px;
    }

    &__logo--heading {
        width: 150px;
        height: 35px;
        object-fit: contain;
        font-family: 'object-fit: contain';
    }

    &__logo--pw {
        width: 230px;
        height: 95px;
        object-fit: contain;
        font-family: 'object-fit: contain';
    }

    &__logo--pw-ytib {
        width: auto;
        height: 210px;
        object-fit: contain;
        font-family: 'object-fit: contain';
    }

    &__image--select-account {
        width: 230px;
        height: auto;
        object-fit: contain;
        font-family: 'object-fit: contain';
    }

    &__image--toggle-mode {
        width: 150px;
        height: auto;
        object-fit: contain;
        font-family: 'object-fit: contain';
    }

    &__image--list-item {
        width: 80%;
        height: auto;
        object-fit: contain;
        font-family: 'object-fit: contain';
        background-color: $white;
    }

    &__image--pwgo-mobile {
        width: 150px;
        height: auto;
        object-fit: contain;
        font-family: 'object-fit: contain';
    }

    &__background {
        width: 600px;
        height: auto;
        object-fit: contain;
        font-family: 'object-fit: contain';
        position: absolute;
        bottom: 0;
    }

    &__background--hnz {
        top: 0;
        left: 30%;
    }

    /* BUTTONS */

    &__button {
        align-self: center;
    }

    &__button--skip.MuiButton-text {
        height: auto;
        padding: 0 5px;
        min-width: auto;
        align-self: flex-start;
    }

    &__button--skip .MuiButton-label {
        font-family: $font-family;
    }

    &__button--close.MuiIconButton-root {
        padding: 8px;
    }

    &__button--close .icon-close {
        color: $black;
        font-size: 14px;
    }

    /* TEXT */

    &__text {
        font-family: $font-family;
        font-size: 14px;
        line-height: 18px;
        margin: 0;
        text-align: center;
    }

    &__text--light {
        color: $white;
    }

    &__text--emphasized {
        font-family: $font-family-semi-bold;
    }

    &__text--extra {
        font-family: $font-family-extra-bold;
    }

    &__text--heading {
        font-family: $font-family-semi-bold;
        font-size: 36px;
        line-height: 44px;
        color: #929292;
    }

    &__text--heading-themed {
        color: $watercourse;
    }

    &__text--subheading {
        font-family: $font-family;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
        text-align: center;
    }

    &__text--subheading {
        font-family: $font-family;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
        text-align: center;
    }

    &__text--subheading-emphasized {
        font-family: $font-family-semi-bold;
        color: $suvaGrey;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &__text--subheading-themed {
        font-family: $font-family-bold;
        color: $watercourse;
    }

    &__text--subheading-big {
        font-size: 58px;
        line-height: 50px;
    }
}