@import '../common-ui/styles/typography';
@import '../common-ui/styles/fonts';
@import '../common-ui/styles/colors';
@import '../common-ui/styles/mixins';
@import './mixins';

// TODO: Update project to use common-ui colours
$offWhite: #fcfcfb;
$tealMain: #66BD9B;
$tealLight: #e0f1eb;
$tealDark: #74c2a5;
$greyMain: #9c9d97;
$greyLight: #bebebe;
$greyDark: #8c8d87;
$redMain: #ed1b23;
$redLight: #f4babc;
$redDark: #d5181f;
$orange: #ffbb00;
// color names taken from https://www.htmlcsscolor.com/
$watercourse: #007749;
$greenWhite: #d4d4d2;
$seashell: #f6f5f4;
$eucalyptus: #33926d;
$whiteSmoke: #f5f5f5;
$persianBlue: #0036D0;
$suvaGrey: #929292;
$dimGrey: #707070;
$delta: #90928C;
$darkGray: #A8A8A8;
$deepSkyBlue: #13B5EA;
$whisper: #ECECEC;
$veryLightGrey: #CCCCCC;
$nero: #292929;
$navyBlue: #1976D2;
$gainsboro: #DFDFDF;
$grey-divider: #808080;
$grey: #858585;
$darkGrey: #ACACAC;
$pattensBlue: #D7DBDD;
$brinkPink: #F2607B;
$starDust: #9B9B95;
$eclipse: #404040;
$jumbo: #858681;
$keppel-light: #66BD9B33;
$pink-swan: #B2B2B2;
$header-text: #E7E7E7;
$shingle-fawn: #7C5F38;

$header-height: 110px;
$subheader-height: 70px;
$footer-height: 100px;
$footer-height-tall: 175px;
$footer-height-auth: 330px;
$footer-height-auth-tall: 370px;

$header-margin-vertical: 30px;
$breadcrumbs-margin-top: 30px; // see muiTheme - MuiBreadcrumbs

$border: 1px solid $black;

$button-fixed-width: 177px;

$list-details-footer-height: 110px;

$content-padding: 50px;

$drawer-width: 475px;
$drawer-width-small: 425px;
$drawer-width-minus-padding: 431px; // padding of 22px on each side
$drawer-width-small-minus-padding: 381px; // padding of 22px on each side