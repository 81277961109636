@import '../../../styles/variables';

.cart-item {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	column-gap: 15px;
	row-gap: 15px;
	min-height: 180px;

	&__content {
		padding: 20px;
	}

	&__details {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;

		grid-column-start: 1;
		grid-column-end: 7;
		grid-row-start: 1;
		grid-row-end: 2;

		&--main {
			margin-bottom: 10px;
		}

		&--clickable {
			cursor: pointer;

			.cart-item__image {
				cursor: pointer;
			}
		}

		&--content {
			display: grid;
			grid-template-columns: 70px 1fr;
			column-gap: 10px;
			margin-top: 10px;
		}

		&--subcontent {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			margin-top: 10px;
		}
	}

	&__actions {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		grid-column-start: 7;
		grid-column-end: 8;
		grid-row-start: 1;
		grid-row-end: 2;

		.cart-actions {
			min-height: unset;
			margin-top: 15px;

			&__button {
				&.MuiButtonBase-root {
					width: 40px;
					height: 40px;
				}
			}

			.order-item-quantity {
				height: 40px;
			}
		}

		&--top {
			display: grid;
			grid-template-columns: 1fr auto;
			column-gap: 10px;
			align-items: flex-start;
			width: 100%;
		}

		&--bottom {
			display: flex;
			flex-direction: column;
		}

		.product-uom {
			&__button {
				&.MuiButtonBase-root {
					font-size: 12px;
					line-height: 15px;
					width: 40px;
					height: 30px;
				}
			}
		}
	}

	.product-image {
		display: flex;
		justify-content: center;
		align-items: center;

		&__icons {
			bottom: 0px;
			right: 0px;
		}
	}

	&__image {
		width: 70px;
		height: 55px;
		background-color: $white;
		object-fit: contain;
		border-radius: 4px;
	}

	&__text {
		font-family: $font-family;
		font-size: 12px;
		line-height: 14px;

		&--heading {
			font-family: $font-family-bold;
			font-size: 14px;
			line-height: 17px;

			/* Limit text length to 2 lines - https://stackoverflow.com/questions/3922739/limit-text-length-to-n-lines-using-css */
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			/* number of lines to show */
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			/* where max-height: = line-height: × <number-of-lines> in em. */
			max-height: 34px;
		}

		&--label {
			font-family: $font-family-medium;
			font-size: 13px;
			line-height: 15px;
		}

		&--price {
			font-family: $font-family-semi-bold;
			font-size: 13px;
			line-height: 15px;
		}
	}

	&__info-icons {
		display: flex;
		flex-direction: row;
		scale: 70%;
		transform-origin: top left;
		margin-top: 5px;

		&>*:not(:last-child) {
			margin-right: 10px;
		}
	}

	&__info-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background-color: $white;

		&.MuiPaper-rounded {
			border-radius: 50%;
			flex-shrink: 0;
		}
	}

	&__comment {
		width: 40px;
		height: 40px;
		max-width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&--visible {
			.MuiButtonBase-root {
				padding: 0px;
				color: $pink-swan;
			}
		}
	}

	&__pricing {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 10px;
		row-gap: 10px;
	}

	&__button {
		&--remove {
			&.MuiButtonBase-root {
				padding: 0px;
			}

			svg {
				fill: $black;
			}
		}

		&--cancel {
			&.MuiButtonBase-root {
				background-color: $white;
				color: $black;
				border: 1px solid $black;

				&:hover {
					color: $black;
				}
			}
		}
	}

	&__input {
		&--root {
			&.MuiInputBase-root {
				padding: 0px;
				background-color: $gainsboro;
				max-height: 120px;
				border-radius: 4px;
			}
		}

		&--multiline {
			&.MuiInputBase-input {
				padding: 15px;
				font-size: 12px;
				line-height: 15px;

				&::placeholder {
					color: $night-rider;
					opacity: 1;
				}
			}
		}
	}

	&__footer {
		grid-column-start: 1;
		grid-column-end: 8;
		grid-row-start: 2;
		grid-row-end: 3;

		&.MuiTextField-root {
			margin-bottom: 10px;
			width: calc(100% - 40px);
			justify-self: center;
		}

		&--remove {
			display: grid;
			grid-template-columns: 0.55fr 0.45fr;
			column-gap: 30px;
			background-color: $gainsboro;
			min-height: 80px;
			margin-bottom: 10px;
			box-sizing: border-box;
			padding: 10px 15px;
		}
	}

	&__remove {
		&--content {
			display: grid;
			grid-template-rows: repeat(2, auto);
			row-gap: 5px;
			align-items: center;
		}

		&--actions {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 10px;
			align-items: center;

			.MuiButton-root {
				height: 30px;
				font-size: 11px;
				line-height: 13px;
			}
		}
	}
}