@import '../../../styles/variables';

.menu-drawer {

    .MuiPaper-root {
        // make full screen at smallest screen size and we show poppers ontop of one another
        width: 480px;
        max-width: 100%;

        @include sm {
            // this gets rendered in single column by MenuPopper component settings
            width: 360px;
            max-width: 360px;
        }

        @include md {
            // perfect size to show two menus side by side at this screen size
            width: 360px;
            max-width: 360px;
        }

        @include xxl {
            width: 476px;
            max-width: 476px;
        }
    }

    .MuiDrawer-paperAnchorLeft {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        box-shadow: none;
        overflow: inherit;
    }

    .MuiButtonBase-root {
        margin-top: 16px;
    }

    &__close {
        &--container {
            padding-top: 36px;
            position: absolute;
            right: 0px;

            @include sm {
                right: -40px;
            }
        }

        &--icon {
            font-size: 40px;
            padding: 20px;
            color: $header-text;
            background-color: $night-rider;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__content {
        background-color: $white;
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100%;
        width: 100%;
    }

    &__header {
        display: flex;
        height: $header-height;
        justify-content: center;
        align-items: center;
        background-color: $night-rider;

        &--text {
            font-family: $font-family-bold;
            font-size: 18px;
            color: $header-text;
            text-transform: uppercase;
            user-select: none;
        }
    }

    &__container {
        overflow: hidden;
    }

    &__list {
        overflow: auto;
        max-height: 100%;
        height: 100%;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            background: $white;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: $suva-grey;
        }
    }

    &__item {
        display: grid;
        grid-template-columns: 56px 1fr 56px;
        height: 56px;
        align-items: center;
        cursor: pointer;

        &--cart {
            grid-template-columns: 56px 1fr auto 56px;
        }

        &:hover {
            background-color: $night-rider;
        }

        &--checked {
            background-color: $night-rider;
        }

        &--column {
            display: flex;
            flex-direction: column;
        }

        &--row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &--title {
            font-family: $font-family-semi-bold;
            font-size: 16px;
            line-height: 19px;
            color: $black;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            :hover>& {
                color: $header-text;
            }

            &__checked {
                color: $keppel;
            }
        }

        &--subtitle {
            font-family: $font-family;
            font-size: 12px;
            line-height: 15px;
            color: $suva-grey;

            &__bold {
                font-family: $font-family-semi-bold;
            }

            :hover>& {
                color: $header-text;
            }
        }

        &--category-label {
            font-family: $font-family-semi-bold;
            font-size: 12px;
            line-height: 15px;
            color: $black;

            width: 35px;
            height: 35px;
            line-height: 35px;
            border: 1px solid $black;
            border-radius: 50%;
            text-align: center;
            align-self: center;
            justify-self: center;

            :hover>& {
                color: $header-text;
                border: 1px solid $header-text;
            }

            &__checked {
                color: $keppel;
                border: 1px solid $keppel;
            }
        }

        &--icon {
            font-size: 26px;
            color: $white;
            text-align: center;

            :hover>& {
                color: $header-text;
            }

            &__checked {
                color: $keppel;
            }
        }

        &--arrow {
            font-size: 30px;
            color: $black;
            text-align: center;

            :hover>& {
                color: $header-text;
            }

            &__checked {
                color: $keppel;
            }
        }

        &--pin {
            font-size: 18px;
            color: $black;
            text-align: center;

            :hover>& {
                color: $header-text;
            }

            &__checked {
                color: $keppel;
            }
        }
    }

    &__product-finders {
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    &__product-finder-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 110px;
        height: 100px;
        border-radius: 16px;
        cursor: pointer;

        &--logo {
            width: 80px;
            height: 80px;
            object-fit: contain;
            font-family: 'object-fit: contain';
        }

        &--logo-container {
            position: relative;
            display: flex;
            height: 80px;
            width: 80px;
        }

        &--title {
            font-size: 10px;
            line-height: 12px;
            font-family: $font-family-extra-bold;
            color: $white;
        }

        &--gold {
            background-color: #5F3907;
        }

        &--hwc {
            background-color: #12956E;

            .menu-drawer__product-finder-item--logo {
                width: 70px;
                height: 70px;
                padding: 5px;
            }
        }

        &--backflow {
            background-color: #127595;
        }

        &--sparkle {
            position: absolute;
            top: 5px;
        }
    }

    &__import-list {
        display: none;

        @include sm {
            display: grid;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;

        &--row {
            background-color: $night-rider;
            color: $header-text;

            &:hover {
                color: $keppel;
            }
        }

        &--row-nzpm {
            background-color: white;
            color: $black;

            &:hover {
                background-color: #007597;
                color: $header-text;
            }

            .menu-drawer__footer--arrow {
                color: $black;

                &:hover {
                    color: $header-text;
                }
            }
        }

        &--title {
            font-family: $font-family-semi-bold;
            font-size: 16px;
            line-height: 19px;
        }

        &--subtitle {
            font-family: $font-family;
            font-size: 12px;
            line-height: 15px;
        }

        &--icon {
            font-size: 28px;
            text-align: center;
        }

        &--arrow {
            font-size: 30px;
            color: $header-text;
            text-align: center;

            :hover>& {
                color: $keppel;
            }
        }

        &--divider.MuiDivider-root {
            border-color: $white;
        }
    }

    &__popper {

        .MuiPaper-root {
            width: 480px;
            max-width: 100%;
            // border-radius: 20px;
            // padding-bottom: 30px;
            box-shadow: none;

            @include sm {
                width: 360px;
                max-width: 360px;
            }

            @include md {
                width: 360px;
                max-width: 360px;
            }

            @include xxl {
                width: 460px;
                max-width: 460px;
            }
        }

        // top row contains the title and is same height as item rows
        &--title-container {
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $night-rider;

            &__small {
                display: grid;
                grid-template-columns: 56px 1fr 56px;
                text-align: center;

                .icon-keyboard_arrow_left {
                    font-size: 30px;
                    color: $white;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        &--title {
            font-family: $font-family-bold;
            font-size: 18px;
            color: $white;
            text-transform: uppercase;
            user-select: none;
        }

        &--children {
            overflow: auto;

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-track {
                background: $white;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: $suva-grey;
            }
        }
    }
}