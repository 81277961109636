/* autoprefixer grid: autoplace */
.menu {
    right: 0;
    z-index: 3;
    transform: translate3d(0px, 155px, 0px) !important;
}

.menu__content {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    padding: 20px 50px;
    position: relative;
}

.menu__indicator--hnz {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 7px;
    background-color: #007749;
}

.menu__content-container {
    width: 1200px;
    min-height: 200px;
}

.menu__text--heading {
    font-family: 'Montserrat-Bold';
}

.menu__text--actions-heading {
    font-family: 'Montserrat-Bold';
    color: #ffffff;
}

.menu__text {
    font-weight: bold;
    cursor: pointer;
}

.menu__text:hover {
    color: #90928c;
}

.menu__items {
    margin-top: 50px;
}

.menu__items-multicolumn {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    column-gap: 20px;
}

.menu__items--vertical .menu__text:not(:last-child) {
    margin-bottom: 20px;
}

.menu__separator {
    position: absolute;
    left: 0;
    right: 0;
    border-style: solid;
    margin-top: 20px;
}

.menu__actions {
    width: 320px;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
}

.menu__actions>* {
    align-self: center;
}

@media only screen and (max-width: 1280px) {
    .menu__content-container {
        width: 100%;
        max-width: 970px;
    }
}

@media only screen and (max-width: 768px) {
    .menu__content-container {
        width: 100%;
        max-width: 750px;
    }

    /* so the drop down appears below the account selector view */
    .menu__content {
        margin-top: 70px;
    }

    .menu__items-multicolumn {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        row-gap: 20px;
    }
}