@import '../../../styles/variables';

.cart-list {
    max-height: 470px;
    overflow-y: scroll;

    /* TEXT */

    &__text--heading {
        font-family: $font-family-semi-bold;
        font-size: 12px;
        line-height: 15px;
        color: $delta;
        text-transform: uppercase;
        margin: 15px 20px 10px;
    }

    &__divider.MuiDivider-root {
        margin-left: 20px;
    }

    &__subtotal--container {
        background-color: $whisper;
        flex-direction: row;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
		align-items: center;
		min-height: 50px;
		box-sizing: border-box;
    }

    &__subtotal {
        font-size: 16px;
		line-height: 20px;
		font-family: $font-family-medium;

        &--price {
            font-family: $font-family-bold;
        }
    }

    &__accordion-header{
        &--in-stock {
            background-color: $keppel;
            color: $white;
        }

        &--limited-stock {
            background-color: $selective_yellow;
            color: $white;
        }

        &--available-to-order {
            background-color: $black;
            color: $white;
        }

		&--hnz {
            background-color: $watercourse;
            color: $white;
        }
    }
}