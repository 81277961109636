@import '../../../styles/variables';

.product-image {
    position: relative;
    overflow: hidden;

    &__image {
        width: 200px;
        height: 200px;
        object-fit: contain;
        font-family: 'object-fit: contain';
    }

    &__content--clickable {
        cursor: pointer;
    }

    &__cart-count {
        background-color: $black;
        color: $white;
        border-radius: 4px;
        padding: 5px;
        min-width: 15px;
        max-width: 30px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__text {
        &--cart-count {
            font-family: $font-family-bold;
            font-size: 14px;
            line-height: 17px;
        }

        &--form-label {
            font-family: $font-family-medium !important;
        }
    }

    &__icons {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        column-gap: 5px;
    }

    &__form {
        @include md {
            position: absolute;
            top: 10px;
            left: 20px;
        }

        @include lg {
            left: 20px;
        }

        .product-image__checkbox svg {
            fill: $black;
        }
    }
}