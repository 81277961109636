@import '../../../styles/variables';

/* autoprefixer grid: autoplace */
.user-profile {

    &__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        column-gap: 22px;
        padding: 22px;

        &--centred {
            display: flex;
            justify-content: center;
            padding: 22px;

            .user-profile__form {
                width: 50%;
            }
        }
    }

    &__section {
        padding-top: 10px;
    }

    &__form {
        width: 100%;

        &--mobile {
            &-select {
                min-width: 70px;

                .MuiSelect-select.MuiSelect-select {
                    padding: 0px 10px;
                    text-align: end;
                }

                .MuiSelect-icon {
                    right: unset;
                    left: 5px;
                }
            }

            .MuiOutlinedInput-adornedStart {
                padding-left: 0;
            }

            .MuiOutlinedInput-inputAdornedStart {
                padding-left: 10px;
                border-left: 1px solid $gainsboro;
            }
        }

        .MuiInputLabel-outlined {
            z-index: 1;
        }
    }

    &__actions {
        display: flex;
        justify-content: space-around;
        margin-top: 22px;
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__overlay {
        position: absolute;
        top: $header-height;
        bottom: 0px;
        width: 100%;
        height: 100%;
        background-color: #00000099;
        z-index: 1;
    }

    &__status {
        .MuiOutlinedInput-input {
            text-transform: capitalize;
        }
    }

    &__role {
        &--editable {
            .MuiOutlinedInput-adornedEnd {
                // removes padding on input adornment to fit select dropdown based on width of input
                padding-right: 0;
            }
        }

        &-info {
            &--editable {
                position: absolute;
                right: 40px;
            }

            .icon-info {
                font-size: 24px;
                line-height: 24px;
                color: $silver;
            }
        }
    }

    &__text {
        font-size: 12px;
        line-height: 15px;
        font-family: $font-family;

        &--section {
            color: $silver;
            text-align: center;
        }

        &--padding {
            margin-top: 22px;
        }

        &--label {
            display: block;
            font-family: $font-family-semi-bold;
            font-size: 14px;
            color: $star-dust;
            cursor: default;
        }

        &--subheading {
            display: block;
            margin-top: 20px;
            // margin-bottom: 20px;
            font-family: $font-family-semi-bold;
            font-size: 12px;
            font-style: italic;
            text-align: center;
        }

        &--button {
            font-family: $font-family-semi-bold;
            font-size: 12px;
            color: $keppel;
            cursor: pointer;
        }
    }

    &__update-footer {
        padding: 15px 0px 15px;
        background-color: $night-rider;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 15px;
        z-index: 1;
		width: 100%;

        &--button {
            width: 70%;
            font-family: $font-family-semi-bold;

            &.MuiButton-containedPrimary.Mui-disabled {
                background-color: $keppel;
            }
        }
    }

    &__preferences {
        padding: 22px;
    }

    &__switch {
        justify-content: space-between;

        &--label {
            font-size: 16px;
        }

        &.MuiFormControlLabel-labelPlacementStart {
            display: flex;
            margin: 5px 0px;
        }
    }

    &__icon-visibility {
        font-size: 24px;
        line-height: 24px;
        color: $silver;
    }
}