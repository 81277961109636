@import '../../../styles/variables';

.role-info {
    // sets container to front-most
    z-index: 9999;

    .MuiPaper-rounded {
        max-width: 275px;
        border-radius: 8px;
        padding: 25px 15px;
    }

    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: right;
        margin-bottom: 30px;

        .icon-info {
            font-size: 24px;
            line-height: 24px;
            color: $silver;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 15px;
        color: $black;

        &--heading {
            font-family: $font-family-bold;
            font-size: 14px;
            line-height: 18px;
            color: $black;
        }

        &--spaced {
            &:not(:last-of-type) {
                margin-bottom: 10px;
            }
        }
    }
}