// color names taken from https://www.htmlcsscolor.com/
$white: #FFFFFF;
$black: #000000;
$ivory: #FCFCFB;
$medium-aquamarine: #74C2A5;
$keppel: #66BD9B;
$clear-day: #E0F1EB;
$jumbo: #8C8D87;
$star-dust: #9C9D97;
$silver: #BEBEBE;
$fire-engine-red: #D5181F;
$alizarin: #ED1B23;
$azalea: #F4BABC;
$cosmos: #F7D4D2;
$selective-yellow: #FFBB00;
$pine-tree: #161715;
$light-grey: #D3D3D3;
$delta: #90928C;
$snow-drift: #E2E2E1;
$watercourse: #007749;
$eucalyptus: #33926D;
$nobel: #9A9A9A;
$dimGray: #717171;
$zambezi: #5B5B5B;
$gainsboro: #DFDFDF;
$grey: #868686;
$jumbo: #858681;
$night-rider: #333333;
$whisper: #E7E7E7;
$nero: #292929;
$suva-grey: #8E8E8E;
$dark-grey: #AFAFAF;
$brink-pink: #F2607B;
$blush: #C24D62;
$grey-suit: #92929E;
$mirky-cotton: #C7C7C7;
$white-smoke: #F5F5F5;
$dolphin: #767680;
$comet: #636366;
$free-speech-red: #B70000;
$matterhorn: #4B4B4B;
$eclipse: #3C3C3C;
$shingle-fawn: #7b5f39;
$warning: #E0A400;