@import '../../../styles/variables';

.comment-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $white;

    &__small {
        width: 30px;
        height: 30px;
    }

    &.MuiPaper-rounded {
        border-radius: 50%;
        flex-shrink: 0;
    }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75%;
        height: 75%;
        border-radius: 50%;
        background-color: $black;

        &--stock {
            background-color: $keppel;
        }

        &--limited {
            background-color: $orange;
        }
    }

    .icon-message {
        font-size: 26px;
        color: $keppel;
    }

    .icon-add_comment {
        font-size: 26px;
        color: $pink-swan;
        // flip icon horizontally
        transform: scaleX(-1);
    }

    &__icon-small {
        &.icon-message {
            font-size: 20px;
        }

        &.icon-add_comment {
            font-size: 20px;
        }
    }
}