@import '../../../styles/variables';

.edit-login-id-dialog {

    .MuiDialogTitle-root {
        padding: 30px 15px 10px;
    }

    .MuiDialogContent-root,
    .MuiDialogActions-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 20px;
    }

    .MuiDivider-root {
        background-color: $greyMain;
        margin-bottom: 20px;
        width: 100%;
    }

    &__text {
        font-family: $font-family;
        font-size: 12px;
        line-height: 15px;
        color: $greyMain;
        text-align: center;
    }
}