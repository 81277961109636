.gold-product {
    height: 30px;
}

.gold-product-paper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: white;
}

.gold-product-paper.MuiPaper-root {
    border-radius: 50%;
}

.gold-product-logo {
    width: 30px;
    height: 30px;
    object-fit: contain;
    font-family: 'object-fit: contain';
}