@import '../../../styles/variables';

/* autoprefixer grid: autoplace */
.subheader-general {
    display: flex;
    justify-content: center;
    height: 70px;
    background-color: $seashell;
    box-sizing: border-box;
    padding-left: 50px;
    padding-right: 50px;

    @include xl {
        padding-left: 0;
        padding-right: 0;
    }

    &__content {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 750px;

        @include md {
            max-width: 970px;
        }

        @include lg {
            justify-content: flex-end;
            width: 1200px;
            max-width: 100%;
        }

        &--partners {
            .subheader-general__auth {
                display: none;

                @include lg {
                    display: flex;
                }
            }
        }
    }

    &__group {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: auto;
        column-gap: 30px;

        @include lg {
            display: flex;
        }

        &>* {
            align-self: center;
            justify-self: center;
        }

        .subheader__text {
            font-size: 18px;
            line-height: 20px;

            @include md {
                font-size: 24px;
                line-height: 29px;
            }

            @include lg {
                font-size: 15px;
                line-height: 19px;
            }

            &.subheader__text--active {
                color: $keppel;

                @include lg {
                    color: $nero;
                }
            }
        }

        .subheader__indicator {
            display: none;

            @include lg {
                display: block;
            }
        }
    }

    &__divider {
        background-color: $dimGray;
        min-height: 40px;

        &--main {
            display: flex;

            @include lg {
                display: none;
            }
        }

        &--partners {
            display: none;

            &.MuiDivider-root {
                margin-left: 45px;
                margin-right: 45px;
            }

            @include lg {
                display: flex;
            }
        }
    }

    &__partners {
        width: 100%;

        @include lg {
            display: flex;
            align-items: center;
            width: unset;
        }
    }

    &__auth {
        width: 100%;

        @include lg {
            width: unset;
        }
    }
}