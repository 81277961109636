// when adding a new icon, download the new font, and copy the unique code from the style.css file
// this will create a new unique url for the icon fonts so the browser won't use the cached version
// $icomoon-unique-code: 'hy4up1';
$icomoon-unique-code: 'kmzn4n';

@font-face {
    font-family: 'icomoon';
    src: url(../assets/fonts/icomoon/icomoon.eot?#{$icomoon-unique-code});
    src: url(../assets/fonts/icomoon/icomoon.eot?#{$icomoon-unique-code}#iefix) format('embedded-opentype'),
    url(../assets/fonts/icomoon/icomoon.ttf?#{$icomoon-unique-code}) format('truetype'),
    url(../assets/fonts/icomoon/icomoon.woff?#{$icomoon-unique-code}) format('woff'),
    url(../assets/fonts/icomoon/icomoon.svg?#{$icomoon-unique-code}#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-felton::before {
    content: "\e938";
}

.icon-kainga-ora-simple::before {
    content: "\e936";
}

.icon-kainga-ora::before {
    content: "\e935";
}

.icon-price-book-request::before {
    content: "\e932";
}

.icon-price-book::before {
    content: "\e933";
}

.icon-plumbing-world::before {
    content: "\e92b";
}

.icon-aqualine::before {
    content: "\e92a";
}

.icon-fergus::before {
    content: "\e919";
}

.icon-metrix::before {
    content: "\e91a";
}

.icon-nzpm::before {
    content: "\e91b";
}

.icon-athena::before {
    content: "\e91c";
}

.icon-caroma::before {
    content: "\e91d";
}

.icon-clearlite::before {
    content: "\e91e";
}

.icon-duravit::before {
    content: "\e91f";
}

.icon-dux::before {
    content: "\e920";
}

.icon-levivi::before {
    content: "\e921";
}

.icon-marley::before {
    content: "\e922";
}

.icon-methven::before {
    content: "\e923";
}

.icon-paini::before {
    content: "\e924";
}

.icon-pipe-king::before {
    content: "\e925";
}

.icon-rheem::before {
    content: "\e926";
}

.icon-rinnai::before {
    content: "\e927";
}

.icon-tiva::before {
    content: "\e928";
}

.icon-toto::before {
    content: "\e929";
}

.icon-double_arrow::before {
    content: "\e918";
}

.icon-list-outline::before {
    content: "\e906";
}

.icon-delete::before {
    content: "\e937";
}

.icon-info::before {
    content: "\e934";
}

.icon-keyboard_arrow_up::before {
    content: "\e930";
}

.icon-keyboard_arrow_down::before {
    content: "\e931";
}

.icon-credit_card::before {
    content: "\e92d";
}

.icon-devices::before {
    content: "\e92e";
}

.icon-storefront::before {
    content: "\e92f";
}

.icon-computer::before {
    content: "\e916";
}

.icon-laptop::before {
    content: "\e916";
}

.icon-phone_iphone::before {
    content: "\e917";
}

.icon-list::before {
    content: "\e905";
}

.icon-user-circle::before {
    content: "\e90f";
}

.icon-shopping-cart::before {
    content: "\e902";
}

.icon-swap_horizontal_circle::before {
    content: "\e913";
}

.icon-library_books::before {
    content: "\e914";
}

.icon-file_download::before {
    content: "\e915";
}

.icon-arrow_drop_up::before {
    content: "\e90b";
}

.icon-arrow_drop_down::before {
    content: "\e90c";
}

.icon-keyboard_arrow_right::before {
    content: "\e90d";
}

.icon-keyboard_arrow_left::before {
    content: "\e90e";
}

.icon-instagram::before {
    content: "\e92c";
}

.icon-youtube::before {
    content: "\e910";
}

.icon-pinterest::before {
    content: "\e911";
}

.icon-facebook::before {
    content: "\e912";
}

.icon-close::before {
    content: "\e908";
}

.icon-no-image::before {
    content: "\e90a";
}

.icon-edit::before {
    content: "\e909";
}

.icon-grid::before {
    content: "\e907";
}

.icon-download::before {
    content: "\e900";
}

.icon-minus::before {
    content: "\e901";
}

.icon-plus::before {
    content: "\e903";
}

.icon-search::before {
    content: "\e904";
}

.icon-calendar-times-o:before {
    content: "\e9e4";
}

.icon-eye::before {
    content: "\e939";
}

.icon-touch_app::before {
    content: "\e93a";
}

.icon-file-pdf::before {
    content: "\e93b";
}

.icon-more_vert::before {
    content: "\e93c";
}

.icon-logout::before {
    content: "\e93d";
}

.icon-mui_search::before {
    content: "\e93e";
}

.icon-add_circle_outline::before {
    content: "\e93f";
}

.icon-format_list_bulleted::before {
    content: "\e940";
}

.icon-close_circle_outline::before {
    content: "\e941";
}

.icon-near_me::before {
    content: "\e942";
}

.icon-create::before {
    content: "\e943";
}

.icon-create-borderless::before {
    content: "\e943";
    border-color: transparent;
}

.icon-clear::before {
    content: "\e944";
}

.icon-attach_file::before {
    content: "\e945";
}

.icon-message::before {
    content: "\e946";
}

.icon-drag_handle::before {
    content: "\e947";
}

.icon-info_outlined::before {
    content: "\e939";
}

.icon-expand::before {
    content: "\e948";
}

.icon-refresh::before {
    content: "\e949";
}

.icon-visibility_off::before {
    content: "\e94a";
}

.icon-visibility::before {
    content: "\e94b";
}

.icon-check_circle::before {
    content: "\e94c";
}

.icon-expand_close::before {
    content: "\e94d";
}

.icon-save::before {
    content: "\e94e";
}

.icon-menu::before {
    content: "\e94f";
}

.icon-attachments::before {
    content: "\e950";
}

.icon-xero::before {
    content: "\e951";
}

.icon-xero_connect::before {
    content: "\e952";
}

.icon-simpro::before {
    content: "\e957";
}

.icon-sync::before {
    content: "\e953";
}

.icon-user-circle-outline::before {
    content: "\e954";
}

.icon-spec::before {
    content: "\e955";
}

.icon-custom-lists::before {
    content: "\e956";
}

.icon-cart-outlined::before {
    content: "\e958";
}

.icon-invoices::before {
    content: "\e959";
}

.icon-open-arrow-thick-right::before {
    content: "\e95a";
}

.icon-bullet::before {
    content: "\e95b";
}

.icon-compare_arrows::before {
    content: "\e95c";
}

.icon-arrow-dropup-circle::before {
    content: "\e95d";
}

.icon-reply::before {
    content: "\e95e";
}

.icon-add_comment::before {
    content: "\e962";
}

.icon-check::before {
    content: "\e960";
}

.icon-water::before {
    content: "\e961";
}

.icon-awesome-info::before {
    content: "\e95f";
}

.icon-push_pin::before {
    content: "\e963";
}

.icon-label::before {
    content: "\e964";
}

.icon-documents::before {
    content: "\e965";
}

.icon-plus_circle::before {
    content: "\e966";
}

.icon-minus_circle::before {
    content: "\e967";
}

.icon-push_pin_outlined::before {
    content: "\e968";
}

.icon-shop::before {
    content: "\e969";
}

.icon-th-menu::before {
    content: "\e96d";
}

.icon-close-round::before {
    content: "\e96c";
}

.icon-lists-round::before {
    content: "\e96b";
}

.icon-arrow-round-back::before {
    content: "\e96a";
}

.icon-new_releases::before {
    content: "\e96e";
}

.icon-user-settings::before {
    content: "\e96d";
}

.icon-credit-hold::before {
    content: "\e970";
}

.icon-wifi_off::before {
    content: "\e971";
}

.icon-admin-settings::before {
    content: "\e96f";
}

.icon-user::before {
    content: "\e972";
}

.icon-content-management::before {
    content: "\e973";
}

.icon-content_copy::before {
    content: "\e975";
}

.icon-help_outline::before {
    content: "\e976";
}

.icon-star::before {
    content: "\e977";
}

.icon-zoom_out::before {
    content: "\e978";
}

.icon-zoom_in::before {
    content: "\e979";
}

.icon-star-outline::before {
    content: "\e97a";
}

.icon-add-to-list::before {
    content: "\e97b";
}

.icon-upload_file::before {
    content: "\e97c";
}

.icon-file_upload::before {
    content: "\e97d";
}

.icon-click-and-collect::before {
    content: "\e97e";
}

.icon-click-and-collect-borderless::before {
    content: "\e97e";
    border-color: transparent;
}

.icon-delivery::before {
    content: "\e97f";
}

.icon-delivery-borderless::before {
    content: "\e97f";
    border-color: transparent;
}

.icon-assignment::before {
    content: "\e980";
}

.icon-invoice::before {
    content: "\e982";
}

.icon-admin::before {
    content: "\e983";
}

.icon-quote::before {
    content: "\e981";
}

.icon-simpro-logo::before {
    content: "\e984";
}

.icon-mail_outline::before {
    content: "\e985";
}

.icon-filter_list::before {
    content: "\e986";
}

.icon-cancel::before {
    content: "\e987";
}

.icon-express-checkout::before {
    content: "\e988";
}

.icon-order-dispatch::before {
    content: "\e989";
}

.icon-document-scanner::before {
    content: "\e98a";
}

.icon-add-round::before {
    content: "\e98b";
}

.icon-grid-round::before {
    content: "\e98c";
}

.icon-order-format::before {
    content: "\e98d";
}

.icon-grid-round-fill::before {
    content: "\e98e";
}

.icon-list-round-fill::before {
    content: "\e98f";
}

.icon-left-arrow-double::before {
    content: "\e990";
}

.icon-upload-round::before {
    content: "\e991";
}

.icon-create-cheat-sheet::before {
    content: "\e992";
}

.icon-bottom-arrow-double::before {
    content: "\e993";
}

.icon-top-arrow-double::before {
    content: "\e994";
}

.icon-right-arrow-double::before {
    content: "\e995";
}

.icon-barcode-scan::before {
    content: "\e996";
}

.icon-search-round::before {
    content: "\e997";
}

.icon-delete_forever::before {
    content: "\e998";
}

.icon-drag_indicator::before {
    content: "\e999";
}

.icon-label-search::before {
    content: "\e99b";
}

.icon-label-create::before {
    content: "\e99c";
}

.icon-calendar::before {
    content: "\e99a";
}

.icon-arrow-down::before {
    content: "\e99f";
}

.icon-arrow-up::before {
    content: "\e9a0";
}

.icon-arrow-right::before {
    content: "\e99d";
}

.icon-arrow-left::before {
    content: "\e99e";
}

.icon-instagram-round::before {
    content: "\e9a1";
}

.icon-facebook-round::before {
    content: "\e9a2";
}

.icon-linkedin-round::before {
    content: "\e9a3";
}

.icon-youtube-round::before {
    content: "\e9a4";
}

.icon-pinterest-round::before {
    content: "\e9a5";
}

.icon-nzpm-flat::before {
    content: "\e9a6";
}

.icon-north::before {
    content: "\e9a7";
}

.icon-sort::before {
    content: "\e9a8";
}

.icon-file-download-round::before {
    content: "\e9a9";
}

.icon-mynzpm::before {
    content: "\e9aa";
}

.icon-request::before {
    content: "\e9ab";
}

.icon-schedule::before {
    content: "\e9ac";
}

.icon-edit_square::before {
    content: "\e9ad";
}

.icon-printer::before {
    content: "\e9ae";
}

.icon-settings::before {
    content: "\e9af";
}

.icon-update_disabled::before {
    content: "\e9b0";
}

.icon-calendar_day::before {
    content: "\e9b1";
}

.icon-calendar_month::before {
    content: "\e9b2";
}

.icon-calendar_week::before {
    content: "\e9b3";
}

.icon-dts::before {
    content: "\e9b4";
}

.icon-attachment::before {
    content: "\e9b5";
}

.icon-brochure::before {
    content: "\e9b6";
}

.icon-installation::before {
    content: "\e9b7";
}

.icon-msds::before {
    content: "\e9b8";
}

.icon-sdoc::before {
    content: "\e9b9";
}

.icon-specsheet::before {
    content: "\e9ba";
}

.icon-warranty::before {
    content: "\e9bb";
}

.icon-scheduled-invoices::before {
    content: "\e9bc";
}

.icon-notifications-active::before {
    content: "\e9bd";
}

.icon-notifications-none::before {
    content: "\e9be";
}

.icon-add-round-filled::before {
    content: "\e9bf";
}

.icon-create-order-label-box::before {
    content: "\e9c0";
}

.icon-dashboard::before {
    content: "\e9c1";
}

.icon-dts-deliver::before {
    content: "\e9c2";
}

.icon-dts-filled::before {
    content: "\e9c3";
}

.icon-order-label-box::before {
    content: "\e9c4";
}

.icon-request-job::before {
    content: "\e9c5";
}

.icon-search-order-label-box::before {
    content: "\e9c6";
}

.icon-pending::before {
    content: "\e9c7";
}

.icon-approved::before {
    content: "\e9c8";
}

.icon-rejected::before {
    content: "\e9c9";
}

.icon-total-cost::before {
    content: "\e9ca";
}

.icon-logout-semi-round::before {
    content: "\e9cb";
}

.icon-settings-filled::before {
    content: "\e9cc";
}

.icon-logs::before {
    content: "\e9cd";
}

.icon-lists-semi-round::before {
    content: "\e9ce";
}

.icon-single-user-settings::before {
    content: "\e9cf";
}

.icon-single-user::before {
    content: "\e9d0";
}

// This icon is bigger compare to the rest of the icons for some reason even though they all have 24 Grid on SVG
.icon-single-user-add:before {
    content: "\e9e3";
    font-size: 30px;
}

.icon-multiple-users::before {
    content: "\e9d1";
}

.icon-bin::before {
    content: "\e9d7";
}

.icon-list-edit::before {
    content: "\e9d2";
}

.icon-copy::before {
    content: "\e9d3";
}

.icon-pinned::before {
    content: "\e9d4";
}

.icon-export::before {
    content: "\e9d5";
}

.icon-import::before {
    content: "\e9d6";
}

.icon-admin-protect::before {
    content: "\e9d8";
}

.icon-recurring-payment::before {
    content: "\e9d9";
}

.icon-order-complete::before {
    content: "\e9da";
}

.icon-product-enquiries-box::before {
    content: "\e9db";
}

.icon-dts-square::before {
    content: "\e9dc";
}

.icon-price-overrides::before {
    content: "\e9dd";
}

.icon-return-orders::before {
    content: "\e9de";
}

.icon-point-of-sale::before {
    content: "\e9df";
}

.icon-job-contracts::before {
    content: "\e9e0";
}

.icon-interim-orders::before {
    content: "\e9e1";
}

.icon-qty-adjust::before {
    content: "\e9e2";
}