@import '../../../styles/variables';

.cart-drawer {

    .MuiDrawer-paperAnchorRight {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        box-shadow: none;
        overflow: inherit;
    }

    &__close {
        &--container {
            padding-top: 36px;
            position: absolute;
            left: unset;

            @include sm {
                left: -40px;
            }
        }

        &--icon {
            font-size: 40px;
            padding: 20px;
            color: $jumbo;
            background-color: $night-rider;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__content {
        background-color: $white;
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100%;
        // make full screen at smallest screen size
        max-width: 100%;

        @include sm {
            width: $drawer-width-small;
            max-width: $drawer-width-small;
        }

        @include xl {
            width: $drawer-width;
            max-width: $drawer-width;
        }
    }

    &__header {
        background-color: $night-rider;
        padding-right: 22px;
        padding-left: 80px;
        text-align: center;
        height: $header-height;
        display: grid;
        grid-template-columns: 1fr auto auto auto;
        column-gap: 10px;
        align-items: center;

        &--text {
            font-family: $font-family-semi-bold;
            font-size: 18px;
            color: $white;
            text-transform: uppercase;
            user-select: none;
            align-self: center;
        }

        &--subtext {
            font-family: $font-family-medium;
            font-size: 12px;
            color: $white;
        }

        &--subtext-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 22px;
        }

        &--icon {
            color: #FFF;
            cursor: pointer;
            font-size: 30px;
        }
    }

    &__container {
        overflow: hidden;
    }

    &__list {
        overflow: auto;
        max-height: 100%;
        height: 100%;

        .cart-list {
            max-height: none;
        }

        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 480px;
            max-width: 100%;
        }

        .MuiList-root {
            overflow-y: hidden;
        }

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            background: $white;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: $suva-grey;
        }
    }

    &__footer {
        background-color: $night-rider;
        padding: 25px 0px;
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        // so footer buttons appear above the overlay
        z-index: 1;

        &--button {
            width: 70%;
        }

        &--aroflo {
            &.MuiButton-root {
                font-family: $font-family-semi-bold;
            }
        }

        form {
            width: 100%;
        }

        .price-summary {
            width: 70%;
            color: $white;
            margin: 0px;
        }

        .price-summary__price-type {
            text-align: left;
            font-family: $font-family;
            font-style: italic;
            color: $white;
        }
    }

    &__overlay {
        position: absolute;
        top: $header-height;
        bottom: 0px;
        width: 100%;
        height: 100%;
        background-color: #00000080;
        z-index: 1;
    }

    &__aroflo {
        width: 110px;
        height: 30px;
        object-fit: contain;
        font-family: 'object-fit: contain';
        margin-left: 10px;
    }
}