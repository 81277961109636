@import '../../../styles/variables';

.cart-actions {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 120px;

	&__button-group {
		&.MuiButtonGroup-root {
			width: fit-content;
			height: fit-content;
		}
	}

	&__button {
		&.MuiButtonBase-root {
			width: 55px;
			height: 55px;
			min-width: unset !important;
			border-radius: 3px;
		}
	}

	.order-item-quantity {
		height: 55px;
		border-radius: 0px;
		margin-left: -1px;
		max-width: unset;

		&.Mui-focused {

			// removes the default outline when the field is focused
			.MuiOutlinedInput-notchedOutline {
				border-color: $black;
				border-width: 1px;
			}
		}

		.MuiOutlinedInput-notchedOutline {
			border-color: $black;
		}
	}
}