@import '../../../styles/variables';

.price-summary {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 20px;
    min-width: 250px;

    &__content {
        display: flex;
        justify-content: space-between;
    }

    &__heading {
        font-family: $font-family-bold;
        font-size: 16px;
        line-height: 18px;
    }

    &__description {
        justify-self: flex-end;
        font-size: 16px;
        line-height: 18px;
        margin-left: 10px;

        &--total {
            font-family: $font-family-bold;
        }
    }

    &__price-type {
        display: block;
        margin-top: 10px;
        font-family: $font-family;
        font-style: italic;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
    }
}
