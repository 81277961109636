@import '../../../styles/variables';

.integration-new-feature {
    .MuiPaper-rounded {
        border-radius: 7px;
    }

    .carousel-pagination {
        bottom: 5%;
    }

    .page {
        &__logo {
            &--heading {
                height: 50px;
            }
        }

        &__background {
            width: auto;
            bottom: -1px;
        }

        &__heading .icon-fergus {
            font-size: 30px;
            color: $white;

            &--dark {
                color: $persianBlue;
            }
        }

        &__indented {
            // positions the image so it's centered horizontally in the dialog
            right: -80px;
        }

        &__down {
            // positions the image so it's centered vertically in the dialog
            bottom: -13px;
        }
    }

    &__pagination {

        .carousel-pagination {
            &__dot {
                background-color: $white;
                border: 0.5px solid transparent;
                border-radius: 100%;

                &--active {
                    background-color: $suvaGrey;
                }
            }
        }

        &--last-index {
            .carousel-pagination {
                &__dot {
                    border-color: $suvaGrey;
                }
            }
        }
    }
}