@import '../../../styles/variables';

.change-branch-confirm-dialog {
    .MuiPaper-rounded {
        border-radius: 9px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-content: center;
        padding-bottom: 20px !important;

        &>*:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__text {
		padding: 10px;

        &--heading {
			font-size: 16px;
			line-height: 19px;
			text-align: center;
			font-family: $font-family-semi-bold;
		}

		&--details {
			text-align: center;
			font-family: $font-family;
			font-size: 12px;
			line-height: 15px;
			color: $grey;
		}

		&--note {
			font-family: $font-family-bold;
		}
    }
}