@import '../../../styles/_variables.scss';

.search-suggestions {
	display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 342px;

	&__container {
		z-index: 10;
		top: 15px !important;

		&--list {
			z-index: 10;

			.search-suggestions__list {
				padding-top: 0;
			}
		}
	}

	&__divider {
		&.MuiDivider-root {
			margin-bottom: 8px;
		}
	}

	&__list {
		max-height: 568px;
		overflow-y: scroll;
		/*  Hide scrollbar for IE and Edge */
		-ms-overflow-style: none;
		/*  Hide scrollbar for Firefox */
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__product-finder {
		&--logo-container {
			width: 66px;
			height: 66px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&--logo {
			height: 90%;
			width: 90%;
			object-fit: contain;
			font-family: 'object-fit: contain';
			transition: all 0.3s;
		}

		&--column {
			display: flex;
			flex-direction: column;
			margin-left: 10px;
		}

		&--gold {
			background-color: #5F3907E6;
			cursor: pointer;
			height: 70px;
			padding: 0px;

			&:hover {
				background-color: $white;

				.search-suggestions__product-finder--text-title,
				.search-suggestions__product-finder--text-body {
					color: $shingle-fawn;
					text-decoration: underline;
				}

				.search-suggestions__product-finder--logo {
					height: 100%;
    				width: 100%;
				}

				.search-suggestions__product-finder--sparkle {
					opacity: 1;
				}
			}

			.search-suggestions__product-finder--text-title,
			.search-suggestions__product-finder--text-body {
				color: $white;
			}
		}

		&--hwc {
			background-color: $keppel-light;
			cursor: pointer;
			height: 70px;
			padding: 0px;

			&:hover {
				.search-suggestions__product-finder--text-title,
				.search-suggestions__product-finder--text-body {
					color: $keppel;
					text-decoration: underline;
				}

				.search-suggestions__product-finder--logo {
					height: 100%;
    				width: 100%;
				}
			}
		}

		&--sparkle {
			position: absolute;
			opacity: 1;
			/* always show sparkle now */
		}

		&--text {
			&-title {
				font-family: $font-family-bold;
				font-size: 16px;
				color: $black;
			}

			&-body {
				font-family: $font-family-medium;
				font-size: 12px;
				color: $black;
			}
		}
	}

	&__text {
		&--message {
			width: 100%;
			max-width: 310px;
			font-size: 12px;
			font-family: $font-family-bold;
			text-align: center;
			color: $star-dust;
		}

		&--empty {
			&-title {
				font-family: $font-family-bold;
				font-size: 16px;
				color: $black;
			}

			&-body {
				font-family: $font-family;
				font-size: 14px;
				justify-content: center !important;
				text-align: center !important;
				color: $black;
			}
		}

		&--view-all {
			font-family: $font-family-bold;
			font-size: 14px;
			color: $black;
			cursor: pointer;

			&:hover {
				color: $medium-aquamarine;
			}

			&.MuiListItem-root {
				justify-content: center;
			}
		}

		&--load-more {
			font-family: $font-family-semi-bold;
			font-size: 12px;
			line-height: 15px;
			color: $medium-aquamarine;
			cursor: pointer;
			

			&.MuiListItem-root {
				justify-content: center;
				padding: 0px;
			}
		}
	}

	.MuiListItem-root {
		&.search-suggestions__loading {
			/* min-width: 342px; */
			justify-content: center;
		}

		&.search-suggestions__empty {
			/* min-width: 342px; */
			flex-direction: column;
			padding-top: 24px;
			padding-bottom: 24px;
		}
	}

	.product-image__cart-count {
		bottom: 0px;

		.product-image__text--cart-count {
			font-size: 11px;
			line-height: 14px;
		}
	}
}

@media only screen and (max-width: 480px) {
    .search-suggestions__quick-search {
        display: none;
    }
}