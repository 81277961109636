@import '../../../styles/variables';

.branch-opening-hours {
	&__text {
		font-family: $font-family-bold;
		font-size: 14px;
		color: $darkGray;
		margin-right: 8px;

		&--open {
			color: $keppel;
		}

		&--closed {
			color: $free-speech-red;
		}
	}

}