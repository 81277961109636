@import '../../../styles/variables';

/* autoprefixer grid: autoplace */
.subheader__container {
    display: flex;
    justify-content: center;
    height: $subheader-height;
    background-color: #f6f5f4;
    box-sizing: border-box;
    position: relative;
}

.subheader__indicator--hnz {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 7px;
    background-color: #007749;
}

.subheader__content {
    width: 1200px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    column-gap: 100px;
}

.subheader__content,
.subheader__content>* {
    align-self: center;
}

.subheader__nav {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    padding-inline-start: 0;
    margin: 0;
}

.subheader__tab {
    display: grid;
    grid-template-columns: min-content;
    grid-template-rows: auto;
    white-space: nowrap;
    cursor: default;
}

.subheader__tab--clickable {
    cursor: pointer;
}

.subheader__tab-content {
    position: relative;
}

.subheader__text {
    color: #292929;
    font-size: 15px;
    line-height: 19px;
    font-family: $font-family-semi-bold;
}

.subheader__indicator {
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 3px;
    width: 100%;
    background-color: #66BD9B;
}

.subheader__option {
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    column-gap: 15px;
}

.subheader__option>* {
    align-self: center;
}

.subheader__account--row {
    display: flex;
    column-gap: 5px;
}

.subheader__label {
    display: flex;
    flex-direction: column;
    flex: 1;

    &--text {
        font-family: $font-family-semi-bold;
        font-size: 13px;
        color: $suva-grey;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 130px;
    }

    &--subtitle {
        font-size: 10px;
        text-decoration: underline;
    }

    &--description {
        font-size: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 90px;
    }
}

.subheader__buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
}

.subheader__button-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-template-rows: auto;
    grid-template-columns: auto;
    column-gap: 10px;
    flex: 1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    padding: 11px 13px;

    &:hover {
        cursor: pointer;
    }
}

.subheader__icon {
    color: $suva-grey;
    font-size: 25px;
    text-align: center;

    &--small {
        font-size: 22px;
    }
}

.subheader__icon--principal {
    color: $keppel;
}

.subheader__image--hnz {
    width: 25px;
    height: 25px;
    text-align: center;
    margin-right: 10px;
    object-fit: contain;
    font-family: 'object-fit: contain';
}

@media only screen and (max-width: 1280px) {
    .subheader__content {
        width: 100%;
        max-width: 970px;
        padding: 0 50px;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 768px) {
    .subheader__container {
        flex-direction: column;
        padding: 20px 50px;
    }

    .subheader__content {
        width: 100%;
        max-width: 750px;
        grid-template-columns: unset;
        grid-template-rows: repeat(2, auto);
        padding: 0;
        column-gap: unset;
    }

    .subheader__nav {
        column-gap: 10px;
    }

    .subheader__select {
        justify-self: flex-end;
        margin-top: 20px;
    }
}