@import '../../../styles/variables';

.list-dialog {
    .MuiPaper-rounded {
        border-radius: 9px;
    }

    .MuiDialogContent-root {
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 50px;
    }

    .MuiTabPanel-root {
        padding: 0px;
        margin-top: 20px;
    }

    &__text {
        font-family: $font-family;
        font-size: 12px;
        line-height: 15px;
        color: $grey;
        text-align: center;
    }

    &__indicator {
        height: 4px;
    }

    &__tab {
        font-family: $font-family;
        font-size: 16px;
        line-height: 19px;
        text-transform: none;
        flex: 1;

        &[aria-selected="true"] {
            font-family: $font-family-semi-bold;
            color: unset;
        }

        &[aria-selected="false"] {
            border-bottom: 1px solid $darkGrey;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}