@import '../../../styles/variables';

.product-uom {
	align-self: flex-end;

	&__button {
		&.MuiButtonBase-root {
			font-family: $font-family;
			font-size: 16px;
			line-height: 20px;
			width: 55px;
			height: 40px;
			border-radius: 3px;
			text-transform: lowercase;
			cursor: default;
		}

		&--selected {
			background-color: $black !important;

			&:hover {
				background-color: $black !important;
			}
		}
	}
}