@import '../../../styles/_variables.scss';

.search-toolbar {
	&.search-toolbar--focused {
		.header-button {
			display: none;
		}

		.search__container {
			display: flex;
		}
	}

	.header-button {
		display: none;

		@include sm {
			display: grid;
		}

		@include lg {
			display: none;
		}
	}

	.search__input {
		background-color: transparent !important;
		width: 100%;

		@include md {
			width: 297px;
		}

		input {
			padding: 0;
			color: $white;
			font-family: $font-family;
			font-size: 10px;

			&::placeholder {
				opacity: 1;
			}

			@include sm {
				font-size: 13px;
			}
		}

		div {
			border-bottom: none;
		}

		&.MuiTextField-root {
			margin: 0;
		}
	}

	.search__icon--clear {
		font-size: 25px;
		color: $header-text;
	}

	.search__container {
		display: flex;
		align-items: center;
		background-color: $night-rider;
		border-radius: 8px;
		height: 50px;
		padding-left: 20px;

		@include sm {
			display: none;
		}

		@include lg {
			display: flex;
		}

		.header-search-image {
			font-size: 25px;
			color: $header-text;
			height: 50px;
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $keppel;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			cursor: pointer;
		}
	}
}