@import '../../../styles/variables';

.labels-small-info-dialog {
    .MuiPaper-rounded {
        border-radius: 9px;
    }

    .MuiButton-root {
        justify-self: center;
        margin-top: 40px;
    }

    .MuiDialog-paperFullWidth {
        padding: 30px;
    }

    &__container {
        justify-content: center;
        display: grid;
    }

    &__content {
        margin: 0 30px;
        justify-content: center;
        display: grid;
        text-align: center;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__separator {
        margin: 20px 0;
        background-color: $darkGrey;
        width: 100%;
        height: 1px;
    }

    &__icon-label {
        font-size: 50px;
        margin-right: 15px;
    }

    &__icon-arrow {
        font-size: 50px;
    }

    &__text {
        font-family: $font-family;
        color: $grey;
        font-size: 18px;
        line-height: 22px;
        margin-top: 10px;

        &--title {
            font-family: $font-family-semi-bold;
            font-size: 31px;
            line-height: 38px;
        }
    }
}