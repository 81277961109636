@import '../../../styles/variables';

.hazard-rating {

	&__paper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background-color: $white;
	}

	&.MuiPaper-rounded {
		border-radius: 50%;
		flex-shrink: 0;
	}

	&__icon {
		width: 35px;
		height: 35px;
		object-fit: contain;
		font-family: 'object-fit: contain';
	}
}