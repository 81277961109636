.search-suggestion {
    display: flex;
    font-family: 'Montserrat-Bold';
    font-size: 12px;
    cursor: pointer;
}

.search-suggestion:hover .search-suggestion__details span {
    text-decoration: underline;
}

.search-suggestion__image {
    width: 50px;
    height: 50px;
    object-fit: contain;
    font-family: 'object-fit: contain';
}

.search-suggestion__details {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.search-suggestion__text--label {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}