@import '../styles/colors';

// Weight: 400
$font-family: "Montserrat", sans-serif;
// Weight: 500 (thick)
$font-family-medium: "Montserrat-Medium", sans-serif;
// Weight: 600 (bold)
$font-family-semi-bold: "Montserrat-SemiBold", sans-serif;
// Weight: 700 (xbold)
$font-family-bold: "Montserrat-Bold", sans-serif;
// Weight: 800 (xxbold)
$font-family-extra-bold: "Montserrat-ExtraBold", sans-serif;

$xxxsm-text-size: 12px;
$xxsm-text-size: 14px;
$xsm-text-size: 16px;
$sm-text-size: 18px;
$text-size: 20px;
$md-text-size: 24px;
$defaultlineHeight: 24px;

// font mixin
@mixin custom-font-style (
    $font: $font-family, 
    $size: $text-size,
    $lineHeight: $defaultlineHeight
) {
    font-family: $font !important;
    font-size: $size !important;
    line-height: $lineHeight !important;
}

.xxxsm-text {
    @include custom-font-style($size: $xxxsm-text-size);

    &__italic {
        @include custom-font-style($size: $xxxsm-text-size);
        font-style: italic;
    }

    &__thick {
        @include custom-font-style($font-family-medium, $xxxsm-text-size);
    }

    &__bold {
        @include custom-font-style($font-family-semi-bold, $xxxsm-text-size);
    }
};

.xxsm-text {
    @include custom-font-style($size: $xxsm-text-size);

    &__italic {
        @include custom-font-style($size: $xxsm-text-size);
        font-style: italic;
    }

    &__thick {
        @include custom-font-style($font-family-medium, $xxsm-text-size);
    }

    &__bold {
        @include custom-font-style($font-family-semi-bold, $xxsm-text-size);
    }
};

.xsm-text {
    @include custom-font-style($size: $xsm-text-size);

    &__italic {
        @include custom-font-style($size: $xsm-text-size);
        font-style: italic;
    }

    &__thick {
        @include custom-font-style($font-family-medium, $xsm-text-size);
    }

    &__bold {
        @include custom-font-style($font-family-semi-bold, $xsm-text-size);
    }
};

.sm-text {
    @include custom-font-style($size: $sm-text-size);

    &__italic {
        @include custom-font-style($size: $sm-text-size);
        font-style: italic;
    }

    &__thick {
        @include custom-font-style($font-family-medium, $sm-text-size);
    }

    &__bold {
        @include custom-font-style($font-family-semi-bold, $sm-text-size);
    }
};

.text {
    @include custom-font-style;

    &__italic {
        @include custom-font-style;
        font-style: italic;
    }

    &__thick {
        @include custom-font-style($font-family-medium);
    }

    &__bold {
        @include custom-font-style($font-family-semi-bold);
    }

    &__xbold {
        @include custom-font-style($font-family-bold);
    }
};

.link {
    @include custom-font-style($font-family-medium, $xxsm-text-size);
    text-decoration: underline;
};

.title {
    @include custom-font-style($font-family-semi-bold, $md-text-size, 29px);
};

