@import '../../../styles/variables';

.version-updates-dialog {
    .MuiPaper-root {
        background: $eclipse;
        padding: 30px;

        &.MuiPaper-rounded {
            border-radius: 7px;
        }
    }

    .MuiDialogContent-root {
        padding: 0px;
        padding-top: 25px;
    }

    h5 {
        font-family: $font-family-bold;
        font-size: 20px;
        line-height: 24px;
        color: $white;
    }

    &__label {
        font-family: $font-family;
        font-size: 13px;
        line-height: 15px;
        color: $white;
    }

    hr {
        background-color: $dimGrey;
        margin-top: 30px;
        height: 1px;
        border-bottom-width: 0px;
    }

    &__divider {

        &--container {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            column-gap: 20px;
            margin-top: 20px;
        }

        &--info {
            display: flex;
            flex-direction: column;
        }

        &--text {
            margin-top: 10px;
            font-family: $font-family;
            font-size: 16px;
            color: $white;
        }
    }

    &__content {
        margin-top: 20px;
        padding-left: 50px;
        padding-right: 50px;

        @include md {
            padding-left: 80px;
            padding-right: 80px;
        }

        @include lg {
            padding-left: 125px;
            padding-right: 125px;
        }

        &--title {
            text-align: center;
        }
    }

    &__header {
        text-align: center;

        &--button {
            &.MuiButtonBase-root {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
    }

    &__logo {
        width: 200px;
        height: 75px;
        object-fit: contain;
        justify-self: center;
        margin-bottom: 20px;
    }

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:not(:last-child) {
            padding-bottom: 20px;
        }

        &--title {
            font-family: $font-family-bold;
            font-size: 16px;
            color: $white;
        }

        &--description {
            font-family: $font-family;
            font-size: 14px;
            color: $white;
        }

        &--content {
            display: flex;
            flex-direction: column;
        }

        &--image {
            align-items: center;
            margin-right: 15px;
            width: 100px;
        }

        &--circle {
            width: 80px;
            height: 80px;
            border-radius: 85px;
            background-color: $night-rider;
            padding: 10px;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
    }

    &--icon-close {
        color: $jumbo;
        font-size: 35px;
    }

    &__new-releases {
        color: $white;
        font-size: 60px;
    }

    &__text {
        font-family: $font-family-semi-bold;
        font-size: 16px;
        color: $white;
        margin-bottom: 10px;
    }

    &__image {
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin-bottom: 5px;
    }

    .MuiDialogActions-root {
        justify-content: center;
        margin-top: 30px;
    }
}