/* autoprefixer grid: autoplace */
.dialog {
    align-items: center;
}

.dialog__text {
    color: #90928c;
    font-size: 12px;
    font-family: 'Montserrat-Bold', 'Arial', sans-serif;
    text-align: center;
}

.dialog__text--title {
    align-self: center;
    text-transform: capitalize;
}

.dialog__text--title h2 {
    font-weight: bold;
}

.dialog__content {
    font-size: 14px;
}

.dialog__actions.MuiDialogActions-root {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    padding: 10px 20px !important;
}

.dialog__message {
    align-self: center;
    margin-bottom: 10px;
}