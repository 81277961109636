@import '../common-ui/styles/icons';
@import '../common-ui/styles/animations';
@import 'variables';

body {
    margin: 0;
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $white;
    overflow-x: hidden;

    &:has(.orders),
    &:has(.checkout-navigator),
    &:has(.products-container),
    &:has(.backflow-landing),
    &:has(.backflow-brand-products),
    &:has(.backflow-type-products),
    &:has(.financials),
    &:has(.list-details) {
        background-color: $whiteSmoke;
    }
}

#root {
    position: relative;
    min-height: 100vh;
}

/**
 * Change autocomplete styles in WebKit Browsers
 * https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: #000000;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

/**
 * Make child's width wider than parent
 * https://stackoverflow.com/a/24895631/7696864
 */
.max-container {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
}

.page-heading {
    margin: 30px 0;
}

.heading {
    font-family: 'Montserrat-SemiBold';
    font-size: 32px;
    font-weight: unset;
    color: #ffffff;
    letter-spacing: 1.5px;
}

.form-control {
    width: 50%;
    align-self: center;
    margin-bottom: 10px !important;
}

.message {
    font-family: $font-family-bold;
    font-size: 14px;
    color: $white;
    margin-top: 5px;
    text-align: center;
}

.message.error {
    color: #ed1b23;
}

.message.success {
    color: #66BD9B;
}

.form-control .info,
.message.info {
    color: #9c9d97;
}

.textfield {
    display: flex;
    flex: 1;
}

.auth-banner {
    display: flex;
    justify-content: center;
    background-color: #000000;
    width: 100vw;
    height: 120px;
    max-height: 120px;
}

.auth-info {
    display: flex;
    flex-direction: column;
    margin: 50px 0 30px;
    padding: 0 20px;
}

.auth-info-text {
    font-family: 'Montserrat-Bold';
    color: #9c9d97;
}

.auth-info-text.subtext {
    font-size: 12px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.separator {
    height: 1px;
    width: 70%;
    background-color: #e2e2e1;
    margin: 10px 0 20px;
}

.button--fixed-width {
    width: unset;

    @include sm {
        width: 177px;
    }
}

.button {
    align-self: center;
    width: 20%;
}

.single-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.single-value__label {
    color: #000000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    margin-left: 10px;
}

.date-picker {
    background-color: transparent !important;
}

.date-picker:not(:last-of-type) {
    margin-right: 50px;
}

/*
 * We hide the text cursor since our select isn't searchable.
 * This fixes the problem for the cursor showing up in IE.
 */
#select__input__not-searchable {
    width: 0;
}

/* ReCaptcha Badge */

.grecaptcha-badge {
    visibility: hidden;
}

/* Text Styles */

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

h1,
h2 {
    font-family: $font-family-bold;
}

a {
    color: $black;
    cursor: pointer;
}

@media only screen and (max-width: 1280px) {
    .form {
        width: 70%;
    }

    .separator {
        width: 80%;
    }

    .button {
        width: 35%;
    }
}

@media only screen and (max-width: 768px) {
    .separator {
        width: calc(100% - 40px);
    }

    .form,
    .button {
        width: 50%;
    }

    .form-control {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {

    .form,
    .button {
        width: calc(100% - 40px);
    }

    .form-control {
        width: 100% !important;
    }
}