@import '../../../styles/variables';

.accounts-table {
    padding: 22px;

    &__table {

        .MuiTableCell-stickyHeader {
            background-color: $white;
        }

        .MuiTableCell-head {
            font-family: $font-family-semi-bold;
            font-size: 14px;
            line-height: 18px;
            color: $suvaGrey;
            border-bottom: 1px solid $dimGrey;
            border-top: 1px solid $dimGrey;
        }

        .MuiTableCell-body {
            font-family: $font-family;
            font-size: 14px;
            line-height: 18px;
            min-height: 70px;
        }
    }

    &__notify {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;

        .icon-info {
            font-size: 15px;
            color: $greyLight;
            margin-left: 5px;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 22px;
    }

    &__button {
        &--update {
            align-self: center;
        }

        &--add {
            width: 70%;
        }

        &--icon {
            color: $white;
            font-size: 30px;
        }
    }

    &__message {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    &__text {
        font-family: $font-family;
        font-size: 12px;
        line-height: 15px;
        color: $black;
        white-space: pre-line;

        &--success {
            color: $keppel;
        }

        &--error {
            color: $alizarin;
        }

        &--em {
            font-family: $font-family-bold;
        }
    }

    &__icon {
        width: 18px;
        height: 18px;
        box-sizing: border-box;

        &--checked {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $greyMain;

            .icon-check {
                font-size: 14px;
                color: $white;
            }
        }

        &--unchecked {
            background-color: $white;
            border: 1px solid $dimGrey;
        }
    }

    .account-status {
        width: 20px;
        height: 20px;
    }

    &__footer {
        display: grid;
        grid-template-columns: repeat(2, auto);
        padding: 22px;

        @include sm {
            grid-template-columns: repeat(4, auto);
        }

        &--status-container {
            display: flex;
            align-items: center;
            column-gap: 6px;
        }

        &--status-icon {
            width: 16px;
            height: 16px;
            border-radius: 100%;
        }

        &--text {
            font-size: 12px;
            line-height: 15px;
            font-family: $font-family;
            color: $suvaGrey;
        }
    }
}