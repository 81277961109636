@import '../../../styles/variables';

.list-category-input {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        // .MuiAutocomplete-input:first-child has left padding of 6px.
        // to match with normal input that has padding of 20px, just subtract the final padding value - becomes 14px.
        padding-left: 14px;
    }

    &__option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 50px;
    }

    &__text {
        font-family: $font-family;
        font-size: 16px;
        line-height: 20px;
        color: $black;

        &--title {
            font-family: $font-family-semi-bold;
            font-size: 20px;
            line-height: 24px;
            color: $black;
        }
    }

    &__icon {
        color: $darkGrey;
    }
}