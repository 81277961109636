@import '../../../styles/variables';

.branch-details {
	&__title {
		display: flex;
		flex-direction: row;
		margin: 18px 0px;
		align-items: center;

		&:hover {
			cursor: pointer;

			&>* {
				color: $keppel;
			}
		}
	}

	&__day {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: 30px;
		width: 70%;
	}

	&__directions {
		display: flex;
		flex-direction: row;
		margin-top: 13px;
		margin-bottom: 20px;
		align-items: center;
		text-decoration: none;

		&:hover {
			cursor: pointer;

			&>* {
				color: $keppel;
			}
		}
	}
	
	&__icon {
		width: 30px;
		font-size: 27px;
		color: $suva-grey;
		text-align: center;

		&--open {
			color: $keppel;
		}

		&--rotate {
			transform: rotate(90deg);
		}
	}

	&__text {
		font-family: $font-family-semi-bold;
		font-size: 14px;
		line-height: 18px;
		color: $dark-grey;

		&--open {
			color: $keppel;
		}

		&--title {
			font-family: $font-family-bold;
		}

		&--hours {
			text-align: end;
		}
	}
}