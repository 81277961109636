@import '../../../styles/variables';

.drawer-accordion {
    display: block;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $whiteSmoke;
        padding: 10px 15px;
        color: $grey;
		min-height: 50px;
		box-sizing: border-box;
    }

    &__horizontal {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    &__text {
        &--heading {
            display: flex;
            font-size: 16px;
            line-height: 20px;
            font-family: $font-family-bold;
			flex-direction: column;
        }

        &--subtitle {
            display: block;
            font-size: 16px;
            line-height: 20px;
            font-family: $font-family;
			text-align: center;
        }

        &--action {
            display: block;
            font-size: 30px;
            cursor: pointer;
            user-select: none;
        }

		&--description {
			display: block;
			font-family: $font-family;
			font-size: 12px;
		}
    }

    &__separator {
        height: 1px;
        background-color: $white;
    }

    &__icon {
        font-size: 22px;
    }
}