@import '../../../styles/variables';

.order-item-quantity {
    min-width: 100px;
    max-width: 125px;
    height: 75px;
    border-radius: 0;

    &__input {
		&.MuiInputBase-input {
			font-family: $font-family-bold;
			font-size: 16px;
			line-height: 20px;
			text-align: center;
		}
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: $dimGray;
    }

	&__text {
		font-family: $font-family-medium;
		font-size: 12px;
		line-height: 15px;
		color: $grey;
	}
}