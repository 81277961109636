@import '../../../styles/variables';

.profile-drawer {

    .MuiDrawer-paperAnchorRight {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        box-shadow: none;
        overflow: inherit;
    }

    &__close {
        &--container {
            padding-top: 36px;
            position: absolute;
            left: unset;

            @include sm {
                left: -40px;
            }
        }

        &--icon {
            font-size: 40px;
            padding: 20px;
            color: $jumbo;
            background-color: $night-rider;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__content {
        background-color: $white;
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;
        // make full screen at smallest screen size
        max-width: 100%;

        @include sm {
            width: $drawer-width-small;
            max-width: $drawer-width-small;
        }

        @include xl {
            width: $drawer-width;
            max-width: $drawer-width;
        }
    }

    &__header {
        background-color: $night-rider;
        display: flex;
        height: $header-height;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0px 80px;

        &--text {
            font-family: $font-family-semi-bold;
            font-size: 18px;
            color: $white;
            text-transform: uppercase;
            user-select: none;
        }

        &--subtext {
            font-family: $font-family-medium;
            font-size: 12px;
            color: $white;
        }
    }

    &__container {
        overflow: hidden;
    }

    &__accounts {
        overflow: auto;
        max-height: 100%;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            background: $white;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: $suva-grey;
        }
    }

    &__footer {
        background-color: $night-rider;
        padding: 15px 0px;
        align-items: center;
        display: flex;
        flex-direction: column;

        &--button {
            width: 70%;
            font-family: $font-family-semi-bold;
        }

        &--icon {
            color: $white;
            font-size: 30px;
        }
    }

    &__account-container {
        padding-top: 18px;
        padding-left: 5px;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    &__branch-content {
        display: flex;
        flex-direction: column;

        &--title {
            font-family: $font-family-bold;
            font-size: 20px;
            color: $black;
            margin-top: 18px;
            margin-bottom: 8px;
        }

        &--title-availability {
            text-transform: uppercase;
        }

        &--text {
            font-family: $font-family-semi-bold;
            font-size: 16px;
            color: $black;
        }

        &--footer-text {
            font-family: $font-family;
            font-style: italic;
            color: $black;
            font-size: 16px;
            text-align: right;
            margin-right: 20px;
            margin-bottom: 10px;
        }

        &--selected {
            height: 35px;
            text-align: end;
        }

    }

    &__divider.MuiDivider-root {
        background-color: $dimGrey;
    }

    &__account-icon {
        width: 25px;
        height: 25px;
        font-size: 30px;
        color: $black;
        margin-right: 18px;
        text-align: center;
        object-fit: contain;
        font-family: 'object-fit: contain';
    }

    &__selected-account {
        width: 25px;
        height: 25px;
        font-size: 30px;
        margin-right: 18px;
        color: $keppel;
    }

    &__circle-background {
        display: flex;
        background-color: white;
        padding: 7px;
        border-radius: 50%;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
        height: fit-content;
        align-self: center;
        margin-right: 20px;
    }
}