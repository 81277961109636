@import '../../../styles/variables';

.app {
    display: flex;
    flex-direction: column;
    padding-bottom: $footer-height-tall;

    @include lg {
        padding-bottom: $footer-height;
    }

    .app-main {
        width: 100%;
        box-sizing: border-box;
        padding-bottom: $content-padding;

        @include md {
            max-width: 750px;
            align-self: center;
        }

        @include lg {
            max-width: 970px;
        }

        @include xl {
            max-width: 1200px;
        }

		&:has(.list-details) {
			padding-bottom: 0;
		}
    }
}

.app.app-auth {
    padding-bottom: $footer-height-auth-tall;

    @include lg {
        padding-bottom: $footer-height-auth;
    }
}