@import '../../../styles/variables';

.labels-menu {

    &__header {
        display: flex;
        align-items: center;
    }

    &__items {
        display: flex;
        justify-content: space-between;
    }

    &__cart-summary {
        display: flex;
        flex-direction: column;
        min-width: 285px;
        border-radius: 42px;
        padding: 35px 40px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);

        &--text {
            color: $black;
            font-size: 16px;
            font-family: $font-family-semi-bold;
        }
    }

    &__button.MuiButtonBase-root {
        margin: 30px 15px 0 15px;
        align-self: center;
    }

    &__tooltip {
        color: $greyLight;
        font-size: 30px;
    }

    .MuiIconButton-root {
        padding: 5px;
        margin-left: 15px;
    }
}