@font-face {
    font-family: 'Montserrat';
    src: url(../assets/fonts/Montserrat/Montserrat-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url(../assets/fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url(../assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url(../assets/fonts/Montserrat/Montserrat-ExtraBold.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url(../assets/fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}