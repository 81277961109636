@import '../../../styles/variables';

/* autoprefixer grid: autoplace */
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    height: $footer-height-tall;
    position: absolute !important;
    bottom: 0;

    @include lg {
        height: $footer-height;
    }
}

.footer-auth {
    height: $footer-height-auth-tall;

    @include lg {
        height: $footer-height-auth;
    }
}

.footer__content {
    width: 1200px;
}

.footer__top {
    display: flex;
    justify-content: center;

    @include md {
        justify-content: unset;
    }
}

.footer__top-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-of-type) {
        margin-right: 100px;
        display: none;

        @include sm {
            display: flex;
        }
    }

    @include sm {
        align-items: unset;
    }
}

.footer__bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

    @include lg {
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
    }
}

.footer__links {
    margin-top: 5px;
    cursor: pointer;
}

.footer__links--clickable:hover {
    text-decoration: underline;
}

.footer__separator {
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    margin: 20px 0 15px;
}

.footer__social {
    text-decoration: none;
}

.footer__social:not(:last-of-type) {
    margin-right: 15px;
}

.footer__badges {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.footer__badges a {
    display: block;
}

.footer__image--apple {
    height: 34px;
    object-fit: contain;
    font-family: 'object-fit: contain';
}

.footer__image--google {
    height: 50px;
    object-fit: contain;
    font-family: 'object-fit: contain';
}

/* TEXT */

.footer__text--heading {
    color: #ffffff;
    margin-bottom: 20px;
}

.footer__text--app-heading {
    color: #ffffff;
    margin-bottom: 10px;
}

.footer__text {
    color: #ffffff;
    font-size: 12px;
    text-decoration: none;
}

.footer__text a:hover,
.footer__top .footer__text:hover {
    text-decoration: underline;
}

.footer__top {
    .footer__text {
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}

.footer__icons {
    margin-bottom: 20px;

    @include lg {
        margin-bottom: 0;
    }
}

.footer__icons>* {
    font-size: 35px;
    color: #ffffff;

    @include md {
        font-size: 50px;
    }
}

.footer__icons>*:not(:last-child) {
    margin-right: 30px;
}

.footer__icons--social {
    font-size: 25px;
    color: #ffffff;
}

.footer__icons--social:hover {
    color: #66BD9B;
}

@media only screen and (min-width: 1280px) {
    .footer__top {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 1280px) {
    .footer__content {
        width: 100%;
        max-width: 970px;
        padding: 0 50px;
        box-sizing: border-box;
    }

    .footer__top {
        padding-top: 0;

        &-item {
            &:not(:last-of-type) {
                margin-right: 50px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .footer__content {
        width: 100%;
        max-width: 750px;
        padding: 0;
    }

    .footer__top-item {
        &:not(:last-of-type) {
            margin-right: 20px;
        }
    }
}