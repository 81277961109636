@import '../../../styles/variables';

.join-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 3;

    &.MuiPaper-rounded {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 30px 0px;
        width: 50%;
    }

    &__text {
        font-size: 12px;
        line-height: 15px;
        font-family: $font-family;

        &--section {
            color: $silver;
            text-align: center;
        }

        &--helper.MuiFormHelperText-root {
            font-family: $font-family;
            font-style: 12px;
            line-height: 15px;
            color: $black;
            cursor: pointer;
        }

        &--label {
            font-family: $font-family-semi-bold;
            font-size: 14px;
            line-height: 18px;
            color: $greyMain;
        }
    }

    &__progress {
        align-self: center;
    }

    .MuiIconButton-root {
        padding: 6px;
    }

    .MuiDivider-root {
        background-color: $dimGrey;
    }
}