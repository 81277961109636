.carousel-pagination {
    display: flex;
    position: absolute;
    bottom: 20px;
    /* Move 50% from left */
    left: 50%;
    /* Move button center position  */
    transform: translateX(-50%);
}

.carousel-pagination__dot {
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background-color: #9c9d97;
}

.carousel-pagination__dot--clickable {
    cursor: pointer;
}

.carousel-pagination__dot:not(:last-of-type) {
    margin-right: 10px;
}

.carousel-pagination__dot--active {
    background-color: #66BD9B;
}