@import '../styles/fonts';

// Mobile-landscape (and larger)
@mixin sm {
    @media (min-width: 480px) {
        @content;
    }
}

// Tablet-portrait (and larger)
@mixin md {
    @media (min-width: 768px) {
        @content;
    }
}

// Tablet-landscape (and larger)
@mixin lg {
    @media (min-width: 992px) {
        @content;
    }
}

// Laptops (and larger)
@mixin xl {
    @media (min-width: 1200px) {
        @content;
    }
}

// PCs (and larger)
@mixin xxl {
    @media (min-width: 1400px) {
        @content;
    }
}


@mixin max-width {
    // fits to width of entire device
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    
    @include lg {
        width: auto;
        position: initial;
        left: unset;
    }
}

// Text overflow
@mixin multi-line($numLines, $lineHeight) {
    /* Limit text length to 2 lines - https://stackoverflow.com/questions/3922739/limit-text-length-to-n-lines-using-css */
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis !important;
	/* number of lines to show */
	-webkit-line-clamp: $numLines;
	line-clamp: $numLines;
	-webkit-box-orient: vertical;
	/* where max-height: = line-height: × <number-of-lines> in em. */
	max-height: calc(#{$lineHeight} * #{$numLines});
}

// Flexbox containers
@mixin container-shared {
    display: flex !important;
    flex-direction: column;
    gap: 1vh;
}

@mixin heading {
    font-family: $font-family-semi-bold;
    font-size: 20px;
    line-height: 24px;
}