@import '../../../styles/variables';

.header__container {
    display: flex;
    justify-content: center;
    min-height: $header-height;
    background-color: $black;
}

.header__content {
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px 30px;
    row-gap: 10px;

    @include md {
        width: 100%;
        max-width: 750px;
        padding: 10px 0px;
    }

    @include lg {
        width: 970px;
        max-width: 100%;
    }

    @include xl {
        width: 1200px;
        max-width: 100%;
    }

    &--row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        @include sm {
            column-gap: 10px;
        }

        &__right-align {
            // when we hide the top left details, we want the top right details to be right aligned
            justify-content: flex-end;
        }
    }
}

.header__transition-enter {
    opacity: 0;
}

.header__transition-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.header__transition-exit {
    opacity: 1;
}

.header__transition-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.logo-container {
    display: flex;
    flex-direction: row;
    column-gap: 30px;

    &--middle {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;

        @include md {
            display: none;
        }
    }

    &--bottom {
        display: none;

        @include md {
            display: flex;
        }
    }
}

.logo {
    height: 45px;
    object-fit: contain;
    font-family: 'object-fit: contain';
    cursor: pointer;

    @include sm {
        height: 50px;
    }

    &--hidden {
        display: none !important;

        @include lg {
            display: unset !important;
        }
    }

    &--aroflo {
        height: 30px;
        cursor: unset;
        align-self: center;

        @include sm {
            height: 35px;
        }
    }
}

.header-cart-image {
    font-size: 25px;
    color: $keppel;
    margin: 0 10px;
}

.header-nav-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    cursor: pointer;
    flex-wrap: wrap;
}

.header-nav-top {
    font-family: $font-family-semi-bold;
    color: $header-text;
    font-size: 12px;
    line-height: 15px;
    white-space: nowrap;

    &--regular {
        font-family: $font-family;
        display: none;

        @include lg {
            display: unset;
        }
    }

    &--bold {
        font-family: $font-family-bold;
    }

    &--highlight {
        color: $keppel;
    }

    &--arrow {
        font-size: 18px;
    }

    &--arrow-nzpm {
        font-size: 18px;

        :hover>& {
            color: #007597;
        }
    }

    &--nzpm {
        font-family: $font-family-semi-bold;

        @include lg {
            font-family: $font-family;
        }

        :hover>& {
            color: #007597;
        }
    }

    &--em {
        font-family: $font-family-bold;
    }
}

.header-nav-top li:hover {
    color: $keppel;
}

.header-nav-top li:not(:last-child) {
    margin-right: 10px;
    padding-right: 10px;
    border-right: solid 2px $header-text;
}

.header-nav-item-hidden {
    display: none;

    @include md {
        display: unset;
    }
}

.header-button {
    height: 50px;
    width: 50px;
    display: grid;
    grid-template-rows: 2fr 1fr;
    align-items: center;
    justify-content: center;
    background-color: $night-rider;
    border-radius: 8px;
    cursor: pointer;

    &--text {
        font-family: $font-family;
        font-size: 10px;
        color: $header-text;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        :hover>& {
            font-family: $font-family-semi-bold;
            font-size: 10px;
            color: $keppel;
        }
    }

    &--icon {
        color: $header-text;
        font-size: 22px;
        text-align: center;
        // -webkit-transition: font-size 0.5s;
        // -moz-transition: font-size 0.5s;
        transition: font-size 0.5s;

        :hover>& {
            font-size: 26px;
            color: $keppel;
        }
    }

    &--hnz {
        width: 20px;
        height: 20px;
        justify-self: center;
        object-fit: contain;
        font-family: 'object-fit: contain';
        transition: all 0.3s;

        :hover>& {
            width: 24px;
            height: 24px;
        }
    }

    &--menu {
        :hover>& {
            .header-button--menu-icon {
                scale: 0.9;
                transition: scale 0.5s;

                .header-button--strip {
                    background-color: $keppel;
                }
            }
        }

        &-icon {
            scale: 0.8;

            .header-button--strip {
                width: 28px;
                height: 4px;
                border-radius: 5px;
                background-color: $header-text;

                &:not(:last-child) {
                    margin-bottom: 7px;
                }
            }
        }
    }

    &--hidden {
        display: none;

        @include sm {
            display: grid;
        }
    }
}

.header-button-popper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-top: 10px;
    min-width: 240px;

    &.MuiPaper-root {
        background-color: $night-rider;
    }

    &__divider {
        width: 100%;

        &.MuiDivider-root {
            background-color: $header-text;
        }
    }

    &__text {
        font-family: $font-family-bold;
        font-size: 12px;
        // line-height: 20px; // to match ▶ arrow height on action text
        white-space: pre-line;
        text-align: center;
        color: $header-text;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;

        &:hover {
            color: $keppel;
        }

        &--title {
            color: $keppel;
        }
    }
}

.header-cart-items-container {
    height: 50px;
    display: flex;
    align-items: center;
    background-color: $night-rider;
    border-radius: 8px;
    padding-right: 15px;
    padding-left: 5px;
    cursor: pointer;
}

.header-cart-items-container:hover .nav__text {
    color: $keppel;
}

.header-cart-items-container-right {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    text-align: end;
}

.header-nav-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}

.header__indicator--hnz {
    width: 100%;
    height: 5px;
    background-color: $watercourse;
}

/* TEXT */

.nav__text {
    color: $header-text;
    font-size: 12px;
    white-space: nowrap;
}

.nav__text--hightlight {
    color: $header-text;
    font-family: $font-family-semi-bold;
}

.nav__text--impersonate {
    display: flex;
    flex-direction: column;
    color: $header-text;
}

.nav__text--heading {
    font-family: $font-family-bold;
    font-size: 17px;
}

.nav__text--details {
    font-size: 12px;
}

.dialog__content .nav__text {
    text-align: center;
}