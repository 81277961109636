@import '../../../styles/variables';

.stock-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $white;

	&.MuiPaper-rounded {
		border-radius: 50%;
		flex-shrink: 0;
	}
    
    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75%;
        height: 75%;
        border-radius: 50%;
        background-color: $black;

        &--stock {
            background-color: $keppel;
        }

        &--limited {
            background-color: $orange;
        }
    }

    &__icon {
        font-size: 18px;
        color: $white;
    }
}