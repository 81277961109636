@import '../../../styles/variables';

.edit-login-id {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__form {
        width: 215px;

        &.MuiFormControl-root {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    &__text {
        font-family: $font-family;
        font-size: 12px;
        line-height: 15px;
        color: $black;

        &--error {
            color: $alizarin;
        }

        &--em {
            font-family: $font-family-bold;
        }
    }
}